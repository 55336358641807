<template>
    <div class="container">
        <div class="content-box">

            <div class="center-box">
                <div class="center-default-box">
                    <div class="price-box">
                        <div class="price-h">
                            产品定价
                            <div class="go-order">
                                <div @click="showMarketOrder = true">查看订单</div>
                            </div>
                        </div>

                        <div class="pay-list" v-if="payList && payList.length > 0">
                            <PriceCom v-for="(item,idx) in payList" :key="idx" :currentPayItem="item"></PriceCom>
                        </div>
                    </div>
                    <div class="partner-box">
                        <div class="partner-center">

                            <div class="center-title">
                                合作伙伴
                            </div>
                            <div class="partner-content">
                                <div class="partner-list">
                                    <div class="partner-item" v-for="(item,idx) in partnerList" :key="idx">
                                        <img :src="item.imgSrc">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="msg-box">
                        <div class="center-title">
                            产品特点
                        </div>
                        <div class="product-list" style="margin-top: 20px;">
                            <div class="product-item" v-for="(item,idx) in descList1" :key="idx">
                                <div class="product-img" v-if="idx % 2 == 1">
                                    <img :src="item.imgSrc">
                                </div>
                                <div class="product-info">
                                    <SecondTitle v-if="item.name" :secTitle="item.name">
                                    </SecondTitle>
                                    <div v-if="item.subDesc" class="desc" style="font-weight: bold;color: #1A232F;">
                                        {{item.subDesc}}</div>
                                    <div class="desc" v-html="item.desc"></div>

                                </div>
                                <div class="product-img" style="margin-left: 20px;" v-if="idx % 2 == 0">
                                    <img :src="item.imgSrc">
                                </div>
                            </div>
                        </div>
                        <div class="center-msg">
                            <div class="center-title">
                                应用场景
                            </div>
                            <div class="skill-list">
                                <div class="skill-item" v-for="(item,idx) in skillList" :key="idx">
                                    <img :src="item.imgSrc">
                                    <div class="center-title">{{item.name}}</div>
                                </div>
                            </div>
                            <div class="ability-list">
                                <div class="ability-item" v-for="(item,idx) in abalityList" :key="idx">
                                    {{item.name}}
                                </div>
                            </div>
                        </div>
                        <div class="product-list product-list1" style="margin-top: 40px;">
                            <div class="product-item" v-for="(item,idx) in descList2" :key="idx">
                                <div class="product-img" v-if="idx % 2 == 1">
                                    <img :src="item.imgSrc">
                                </div>
                                <div class="product-info">
                                    <SecondTitle v-if="item.name" :secTitle="item.name">
                                    </SecondTitle>
                                    <div v-if="item.subDesc" class="desc" style="font-weight: bold;color: #1A232F;">
                                        {{item.subDesc}}</div>
                                    <div class="desc" v-html="item.desc"></div>

                                </div>
                                <div class="product-img" style="margin-left: 20px;" v-if="idx % 2 == 0">
                                    <img :src="item.imgSrc">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="msg-box">
                        <div class="center-title">
                            产品特点
                        </div>
                        <div class="product-list" style="margin-top: 20px;">
                            <div class="product-item" v-for="(item,idx) in descList3" :key="idx">
                                <div class="product-img" v-if="idx % 2 == 1">
                                    <img :src="item.imgSrc">
                                </div>
                                <div class="product-info">
                                    <SecondTitle v-if="item.name" :secTitle="item.name">
                                    </SecondTitle>
                                    <div v-if="item.subDesc" class="desc" style="font-weight: bold;color: #1A232F;">
                                        {{item.subDesc}}</div>
                                    <div class="desc" v-html="item.desc"></div>

                                </div>
                                <div class="product-img" style="margin-left: 20px;" v-if="idx % 2 == 0">
                                    <img :src="item.imgSrc">
                                </div>
                            </div>
                        </div>
                        <div class="center-msg">
                            <div class="center-title">
                                应用场景
                            </div>
                            <div class="skill-list">
                                <div class="skill-item" v-for="(item,idx) in skillList1" :key="idx">
                                    <img :src="item.imgSrc">
                                    <div class="center-title">{{item.name}}</div>
                                </div>
                            </div>
                            <div class="ability-list">
                                <div class="ability-item" v-for="(item,idx) in abalityList2" :key="idx">
                                    {{item.name}}
                                </div>
                            </div>
                        </div>
                        <div class="product-list product-list1" style="margin-top: 40px;">
                            <div class="product-item" v-for="(item,idx) in descList4" :key="idx">
                                <div class="product-img" v-if="idx % 2 == 1">
                                    <img :src="item.imgSrc">
                                </div>
                                <div class="product-info">
                                    <SecondTitle v-if="item.name" :secTitle="item.name">
                                    </SecondTitle>
                                    <div v-if="item.subDesc" class="desc" style="font-weight: bold;color: #1A232F;">
                                        {{item.subDesc}}</div>
                                    <div class="desc" v-html="item.desc"></div>

                                </div>
                                <div class="product-img" style="margin-left: 20px;" v-if="idx % 2 == 0">
                                    <img :src="item.imgSrc">
                                </div>
                            </div>
                        </div>
                    </div> -->

                    <div class="market-order-popup" v-if="showMarketOrder">
                        <MarketOrder @closeMarketOrder="showMarketOrder = false" @goPopupPay="goPopupPay"></MarketOrder>
                    </div>
                </div>

            </div>

        </div>
        <PayCom v-if="showPay" :payInfo="payInfo" @closePay="closePay" :isFromOrder="isFromOrder"
            :orderCurrentInfo="orderInfo"></PayCom>
    </div>
</template>
<script>
    import PriceCom from "@/components/price/index.vue";
    import MarketOrder from "@/components/market/marketOrder.vue";
    import PayCom from "@/components/market/pay.vue";
    import SecondTitle from "@/components/secondTitle/index.vue";
    import { getStatisticsOrderProducts } from "@/api/statisticsOrder";
    import util from "@/utils/util";
    export default {
        components: {
            SecondTitle,
            MarketOrder,
            PriceCom
        },
        data() {
            return {
                partnerList: [{
                    imgSrc: require("@/assets/images/common/huoban10.png")
                }, {
                    imgSrc: require("@/assets/images/common/huoban9.png")
                }],
                showPay: false,
                payInfo: null,
                showMarketOrder: false,
                isFromOrder: false,
                orderInfo: null,
                showMarketOrder: false,
                payList: [],
                descList1: [{
                    name: "市场预测与IP分析",
                    subDesc: "",
                    desc: "1- 全方位市场洞察：结合玩具市场趋势分析和IP信息挖掘，提供全面的市场和消费者洞察。<br>2- 智能化决策支持：通过数据驱动的分析，帮助店铺制定精准的营销策略、选品计划和市场定位。<br>3- 多维度数据分析：涵盖市场趋势、IP特性、消费者偏好、价格分布、媒体热度等多维度数据，为店铺提供深刻的市场理解。<br>4- 动态趋势预测：实时分析市场和IP的动态变化，帮助店铺快速响应市场变化和消费者需求。",
                    imgSrc: require("@/assets/images/common/scyc.png")
                }],
                descList2: [{
                    name: "数据驱动决策",
                    subDesc: "",
                    desc: "基于详实的数据分析，帮助店铺制定精准的营销策略和商品选品计划。",
                    imgSrc: require("@/assets/images/common/sjqdjc.png")
                }, {
                    name: "市场竞争力提升",
                    subDesc: "",
                    desc: "通过优化产品组合和定价策略，提高市场竞争力和盈利能力。",
                    imgSrc: require("@/assets/images/common/scjz.png")
                }, {
                    name: "增强IP洞察",
                    subDesc: "",
                    desc: "通过流量指数和IP分析，深度挖掘该IP真实的市场影响力。",
                    imgSrc: require("@/assets/images/common/zqdc.png")
                }],
                skillList: [{
                    name: "战略规划与决策",
                    imgSrc: require("@/assets/images/common/zlgh.png")
                }, {
                    name: "竞争分析与优化",
                    imgSrc: require("@/assets/images/common/jzfx.png")
                }, {
                    name: "营销分析与制定",
                    imgSrc: require("@/assets/images/common/yxfx.png")
                }, {
                    name: "商品选品与指导",
                    imgSrc: require("@/assets/images/common/spxp.png")
                }],
                abalityList: [{
                    name: "战略规划与决策：支持店铺决策者进行战略规划，识别市场机会和潜在风险，优化资源配置。"
                }, {
                    name: "竞争分析与优化：帮助店铺了解市场和IP的竞争态势，制定相应的竞争策略，优化市场表现。"
                }, {
                    name: "营销分析与制定：帮助店铺制定更有效的推广策略，精准定标消费者，提高市场渗透率。"
                }, {
                    name: "商品选品与指导：为店铺提供市场趋势和消费者偏好的数据支持，IP应用建议，指导新商品的选品。"
                }],

                descList3: [{
                    name: "新品预演",
                    subDesc: "",
                    desc: "1- 市场洞察与竞争力提升：通过市场现状分析和IP竞争力评估，帮助了解市场结构和消费者需求，优化产品组合和定价策略，提升市场竞争力和盈利能力。<br>2- 精准预测与策略优化：提供爆款概率预测和详细的爆款策略建议，从创新设计到市场推广，为提供全方位的指导，助力产品在市场中脱颖而出。<br>3- 智能交互与个性化支持：通过自由问答功能，可以就玩具的任何问题与AI互动，获得个性化的解答和建议。",
                    imgSrc: require("@/assets/images/common/xpyy.png")
                }],
                descList4: [{
                    name: "智能市场分析",
                    subDesc: "",
                    desc: "通过统计分析当前市场现状，帮助厂家了解市场结构和消费者需求，优化产品组合和定价策略。",
                    imgSrc: require("@/assets/images/common/znscfx.png")
                }, {
                    name: "IP适配与竞争力评估",
                    subDesc: "",
                    desc: "提供IP与玩具品类的适配度评分和竞争力分析，帮助厂家选择最具潜力的IP进行产品开发。",
                    imgSrc: require("@/assets/images/common/ipsp.png")
                }, {
                    name: "爆款预测与策略指导",
                    subDesc: "",
                    desc: "利用AI技术预测产品的爆款概率，并提供详细的策略建议，从设计到市场推广全方位支持。",
                    imgSrc: require("@/assets/images/common/bkyc.png")
                }],
                skillList1: [{
                    name: "战略规划与决策",
                    imgSrc: require("@/assets/images/common/zlgh.png")
                }, {
                    name: "竞争分析与优化",
                    imgSrc: require("@/assets/images/common/jzfx.png")
                }, {
                    name: "新品开发与创新",
                    imgSrc: require("@/assets/images/common/xpkf.png")
                }, {
                    name: "市场营销与推广",
                    imgSrc: require("@/assets/images/common/sctg.png")
                }],
                abalityList2: [{
                    name: "战略规划与决策：支持厂商高层进行战略规划，识别市场机会和潜在风险，优化资源配置。"
                }, {
                    name: "竞争分析与优化：帮助厂商了解市场和IP的竞争态势，制定相应的竞争策略，优化市场表现。"
                }, {
                    name: "新品开发与创新：为产品开发团队提供市场现状分析和IP选择建议，指导新产品的设计和创新。"
                }, {
                    name: "市场营销与推广：帮助市场营销团队制定更有效的推广策略，提升产品的市场渗透率和品牌影响力。"
                }],
                userInfo: {
                    id: "",
                    nickname: "",
                    mobile: "",
                    avatar: "",
                    gender: "",
                    desc: "",
                    real_name: "",
                    character: "",
                    vip_level: 0,
                    vip_expires_in: "",
                    coin: 0,
                    income: 0,
                    balance: 0,
                    freeze: 0,
                    token: "",
                },
            }
        },
        created() {
            util.onLogin().then((res) => {
                if (!res) {
                    this.$bus.$emit("openLogin");
                } else {
                    this.getUserInfo();
                    this.getStatisticsOrderProductsReq();
                }
            });


            this.$bus.$off("initData");
            this.$bus.$on("initData", () => {
                this.getUserInfo();
                this.getStatisticsOrderProductsReq();
            });

            this.$bus.$off("paySuccess");
            this.$bus.$on("paySuccess", () => {
                this.getUserInfo();
            });
        },
        methods: {
            goPopupPay(val) {
                this.isFromOrder = true;
                this.orderInfo = val;
                this.showPay = true;
            },
            getUserInfo() {
                this.$store
                    .dispatch("GetInfo", "")
                    .then((res) => {
                        this.userInfo = res;
                        // this.$refs.orderRef.initOrder();
                        console.log("userInfo", this.userInfo);
                    })
                    .catch((err) => {
                        this.$message.error((err && err.msg) || "服务器出错，请稍后重试");
                    });
            },
            getStatisticsOrderProductsReq() {
                getStatisticsOrderProducts()
                    .then((res) => {
                        console.log("res", res);
                        this.payList = (res && res.data && res.data.list) || [];
                    })
                    .catch(() => {
                        this.payList = [];
                    });
            },
        },
    }
</script>
<style lang="less" scoped>
    .container {
        height: 100%;
        display: flex;
        /* 禁止文字选择 */
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        .menu-layer {
            position: fixed;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: transparent;
            z-index: 999;
        }

        .content-box {
            display: flex;
            padding-top: 128px;
            flex: 1;

            .left-box {
                width: 450px;
                display: flex;
            }

            .center-box {
                /* flex: 1; */
                display: flex;
                width: 1000px;
                margin: 0 auto;

                .center-default-box {
                    width: 100%;
                    margin-bottom: 20px;
                    display: flex;
                    background: #f8f8fa;
                    border-radius: 20px;
                    flex-direction: column;
                    position: relative;
                    overflow-y: auto;
                    padding-bottom: 20px;

                    .problem-wrap {
                        margin-top: 20px;
                    }

                    .pay-box {
                        display: flex;
                        flex-direction: column;
                    }

                    .price-h {
                        font-weight: bold;
                        font-size: 17px;
                        color: #1a232f;
                        line-height: 24px;
                        text-align: left;
                        padding-top: 30px;
                        padding-left: 20px;
                        padding-right: 20px;
                        display: flex;
                        justify-content: space-between;

                        .go-order {
                            font-weight: 400;
                            font-size: 14px;
                            color: #3672fd;
                            text-align: center;
                            font-style: normal;
                            cursor: pointer;
                        }
                    }

                    .pay-list {
                        display: flex;
                        justify-content: center;
                        margin-top: 20px;
                        padding: 0 20px;
                    }

                    .msg-box {
                        margin: 0 20px;
                        margin-top: 20px;
                        display: flex;
                        flex-direction: column;
                        background: #fff;
                        border-radius: 20px;
                        padding: 30px;

                        .product-list {
                            display: flex;
                            flex-direction: column;
                            /* margin-top: 50px; */
                            width: 100%;

                            &.product-list1 {
                                .product-item {
                                    .product-img {
                                        img {
                                            width: 256px;
                                            height: 256px;
                                            border-radius: 10px;
                                        }
                                    }
                                }
                            }

                            .product-item {
                                display: flex;
                                margin-bottom: 60px;
                                cursor: pointer;
                                width: 100%;

                                .product-img {
                                    img {
                                        width: 448px;
                                        height: 256px;
                                        border-radius: 10px;
                                    }
                                }

                                .product-info {
                                    display: flex;
                                    flex-direction: column;
                                    padding-left: 30px;
                                    flex: 1;

                                    .desc {
                                        font-weight: 400;
                                        font-size: 18px;
                                        color: #626a83;
                                        line-height: 24px;
                                        text-align: justify;
                                        font-style: normal;
                                        margin-top: 24px;
                                        padding-left: 16px;
                                        flex: 1;
                                    }

                                    .opt {
                                        display: flex;

                                        justify-content: flex-end;

                                        .btn {
                                            width: 150px;
                                            height: 40px;
                                            background: #3672fd;
                                            border-radius: 10px;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            font-weight: bold;
                                            font-size: 17px;
                                            color: #ffffff;
                                            line-height: 24px;
                                        }
                                    }
                                }
                            }
                        }

                        .skill-list {
                            width: 100%;
                            display: flex;
                            margin-top: 20px;

                            .skill-item {
                                flex: 1;
                                display: flex;
                                flex-direction: column;
                                align-items: center;

                                img {
                                    width: 120px;
                                    height: 120px;
                                }

                                .center-title {
                                    margin-top: 10px;
                                    padding-left: 0;
                                    font-size: 18px;
                                    line-height: 24px;
                                }
                            }
                        }

                        .ability-list {
                            width: 100%;
                            display: flex;
                            margin-top: 20px;
                            flex-direction: column;
                            padding-left: 40px;

                            .ability-item {
                                width: 100%;
                                font-weight: 400;
                                font-size: 18px;
                                color: #626a83;
                                line-height: 24px;
                            }
                        }
                    }

                    .center-title {
                        font-weight: bold;
                        font-size: 30px;
                        color: #1a232f;
                        line-height: 26px;
                        text-align: center;
                        /* padding-left: 20px; */
                    }
                }
            }

            .right-box {
                width: 300px;
                display: flex;
            }
        }

        .market-order-popup {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            background: #f8f8fa;
            border-radius: 20px;
            z-index: 10;
        }
    }

    .partner-box {
        margin: 0 20px;
        margin-top: 80px;
        position: relative;
        background: #fff;
        padding-top: 30px;
        .partner-center {
            width: 100%;
            display: flex;
            margin: 0 auto;
            flex-direction: column;

            .partner-content {
                width: 100%;
                display: flex;
                position: relative;

                .partner-list {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    margin-top: 50px;

                    .partner-item {
                        width: 280px;
                        height: 120px;
                        background: #FFFFFF;
                        border-radius: 5px;
                        margin-right: 50px;
                        margin-bottom: 30px;
                        &:last-child {
                            margin-right: 0;
                        }

                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 5px;
                        }
                    }
                }
            }
        }
    }
</style>