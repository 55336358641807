<template>
    <div class="pay-box">
       
        <div class="pay-item">
            <div class="pay-name">{{payItem && payItem.cat_name}}</div>
            <div class="pay-desc" v-html="payItem && payItem.cat_desc"></div>
            
            <el-table v-if="payItem.cat_ability_list && payItem.cat_ability_list.length > 0" :data="payItem.cat_ability_list"
                border style="width: 100%;margin-top: 30px;flex:none;">
                <el-table-column prop="name" label="功能名称">
                    <template slot-scope="scope">
                        <div>{{scope.row}}</div>


                    </template>
                </el-table-column>
                <el-table-column prop="skill" label="包含功能">
                    <template slot-scope="scope">
                        <div class="has">

                        </div>

                    </template>
                </el-table-column>
            </el-table>
            <div class="pay-name" style="margin-top: 20px;">产品价格</div>
            <!-- <el-select style="margin-top: 20px;" v-model="payItem.productId" clearable
                placeholder="请选择" @change="handleChange">
                <el-option v-for="(subItem,subIdx) in payItem.products" :key="subItem.id" :label="subItem.name"
                    :value="subItem.id">
                </el-option>
            </el-select> -->
            <el-radio-group v-if="payItem.products && payItem.products.length > 0" v-model="payItem.productId" @input="changePay">
                <el-radio class="radio-box" v-for="(subItem,subIdx) in payItem.products" :label="subItem.id" :key="subItem.id">
                    <div class="radio-item">
                        <!-- <div class="pay-name">{{subItem.name}}</div> -->
                        <div class="pay-name">{{subItem.days}}天</div>
                        <div class="discount"><span class="unit">¥</span>{{subItem.price / 100}}
                        </div>
                        <div class="count" v-if="calShowPirce(subItem.price,subItem.origin_price)"><span
                                class="unit">¥</span>{{subItem.origin_price / 100}}
                        </div>
                    </div>
                    
                </el-radio>
            </el-radio-group>
    
            <div class="other" v-if="(payItem.products && payItem.products.length == 0) || !payItem.products">
                <div>联系客服面议</div>
                <div class="other-desc">
                    <img style="width: 200px;height: 200px;" src="@/assets/images/qiyewx.png">


                </div>

            </div>
            <div class="price-bottom" v-if="payItem.products && payItem.products.length > 0">
                <div class="flex flex-1">
                    <div class="discount"><span class="unit">¥</span>{{payItem && payItem.currentPrice / 100}}
                    </div>
                    <div class="count" v-if="calShowPirce(payItem.currentPrice,payItem.originPrice)"><span
                            class="unit">¥</span>{{payItem && payItem.originPrice / 100}}
                    </div>
                </div>
                
                <div class="pay-btn" @click="goPay()">购买</div>
            </div>
        </div>
        <PayCom v-if="showPay" :payInfo="payInfo" @closePay="closePay" :isFromOrder="isFromOrder" :orderCurrentInfo="orderInfo"></PayCom>
    </div>
</template>
<script>
    import PayCom from "../market/pay.vue";
    export default {
        props: ["currentPayItem"],
        components: {
            PayCom
        },
        data() {
            return {
                payItem: null,
                showPay: false,
                payInfo: null,
                showMarketOrder: false,
                isFromOrder: false,
                orderInfo: null
            }
        },
        created() {
            console.log("currentPayItem",this.currentPayItem)
            this.payItem = this.currentPayItem;
            this.initPay()
        },
        methods: {
            changePay(productId) {
                console.log("changePay",productId)
                let productItem = this.payItem.products.find((res) => {
                    return res.id == productId;
                });

               
                this.$set(this.payItem, "currentProduct",productItem)
                this.$set(this.payItem, "currentPrice",productItem.price)
                this.$set(this.payItem, "originPrice",productItem.origin_price)
                this.$set(this.payItem, "productId",productItem.id)
            },
            initPay() {
                if(this.payItem.products && this.payItem.products.length > 0) {
                        let payFirstItem = this.payItem.products[0];
             
                        this.$set(this.payItem, "currentProduct",payFirstItem)
                        this.$set(this.payItem, "currentPrice",payFirstItem.price)
                        this.$set(this.payItem, "originPrice",payFirstItem.origin_price)
                        this.$set(this.payItem, "productId",payFirstItem.id)
                    } else {
                       
                        this.$set(this.payItem, "currentProduct",null)
                        this.$set(this.payItem, "currentPrice",0)
                        this.$set(this.payItem, "originPrice",0)
                        this.$set(this.payItem, "productId",null)
                    }
            },
            closePay() {
                this.showPay = false;
                this.isFromOrder = false;
            },
            goPay() {
                this.isFromOrder = false;
                this.orderInfo = null;
                this.payInfo = this.payItem.currentProduct;
                this.showPay = true;
            },
            handleChange(productId) {
                console.log('handleChange',productId);
                let productItem = this.payItem.products.find((res) => {
                    return res.id == productId;
                });

               
                this.$set(this.payItem, "currentProduct",productItem)
                this.$set(this.payItem, "currentPrice",productItem.price)
                this.$set(this.payItem, "originPrice",productItem.origin_price)
                this.$set(this.payItem, "productId",productItem.id)
                console.log("payItem",this.payItem)
               
            },
            calShowPirce(discount, count) {
                if (discount != count) {
                    return true;
                } else {
                    return false;
                }
            },
            calDiscount(discount, count) {
                return (
                    parseFloat((Number(discount) / Number(count)) * 10).toFixed(0) + "折"
                );
            },
        }
    }
</script>
<style lang="less" scoped>
    .pay-box {
        display: flex;
        position: relative;
        width: 360px;
        padding-bottom: 30px;
        margin-right: 60px;

        .pay-item {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                background: #fff;
                border-radius: 20px;
                padding: 30px;

                .pay-name {
                    font-weight: bold;
                    font-size: 17px;
                    color: #1a232f;
                    line-height: 24px;
                    text-align: left;
                }

                .pay-desc {
                    font-weight: 400;
                    font-size: 14px;
                    color: #626a83;
                    line-height: 20px;
                    text-align: left;
                    margin-top: 4px;
                }

                .radio-box {
                    display: flex;
                    margin-top: 20px;
                    align-items: center;
                    width: 100%;
                    ::v-deep .el-radio__label {
                        width: 100%;
                        border: 1px solid #EBEEF5;
                        margin-left: 20px;
                        padding: 8px 0;
                    }
                    .radio-item {
                        display: flex;
                        align-items: center;
                       
                        width: 100%;
                        .unit {
                            font-size: 14px;
                        }
                        .pay-name {
                            padding-left: 20px;
                        }

                        .discount {
                            font-weight: bold;
                            font-size: 20px;
                            color: #3672fd;
                            line-height: 20px;
                            text-align: left;
                            margin-left: 30px;
                        }

                        .count {
                            font-weight: 400;
                            font-size: 14px;
                            color: #979db1;
                            line-height: 20px;
                            text-align: left;
                            text-decoration-line: line-through;
                            margin-left: 12px;
                        }

                    }
                }

                .has {
                    width: 15px;
                    height: 15px;
                    border-radius: 100%;
                    border: 1px solid #1a232f;
                    background: #1a232f;
                    margin: 0 auto;
                }

                .other {
                    display: flex;
                    flex-direction: column;
                    font-weight: bold;
                    font-size: 17px;
                    color: #1a232f;
                    line-height: 24px;
                    text-align: left;
                    margin-top: 20px;

                    .other-desc {
                        display: flex;

                        font-size: 14px;
                        color: #626a83;
                        font-weight: normal;
                        flex-direction: column;

                        .other-item {
                            width: 100%;
                            display: flex;
                            justify-content: space-between;
                            margin-top: 10px;
                        }
                    }
                }

                .price-bottom {
                    display: flex;
                    flex: 1;
                    align-items: flex-end;
                    justify-content: space-between;

                    .unit {
                        font-size: 14px;
                    }

                    .discount {
                        font-weight: bold;
                        font-size: 20px;
                        color: #3672fd;
                        line-height: 20px;
                        text-align: left;
                    }

                    .count {
                        font-weight: 400;
                        font-size: 14px;
                        color: #979db1;
                        line-height: 20px;
                        text-align: left;
                        text-decoration-line: line-through;
                        margin-left: 12px;
                    }

                    .pay-btn {
                        width: 112px;
                        height: 28px;
                        background: #3672fd;
                        box-shadow: 0px 2px 4px 0px #d9e5ff;
                        border-radius: 18px;
                        font-weight: 400;
                        font-size: 12px;
                        color: #ffffff;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin: 0 auto;
                        margin-top: 20px;
                    }
                }
            }
    }
</style>