<template>
  <div class="pay-warp-popup">
    <div class="pay-popup-content">
      <i class="el-icon-circle-close icon-defalut-close cursor" @click="close"></i>
      <div class="default-title">结算详情</div>
      <div class="product-info">
        <div class="product-title">
          {{orderInfo && orderInfo.product && orderInfo.product.name || ""}}
        </div>
        <div class="product-desc">
          {{orderInfo && orderInfo.product && orderInfo.product.desc || ""}}
        </div>
      </div>
      <div class="pay-info">
        <div class="pay-item">
          <div class="pay-left">
            商品价格
          </div>
          <div class="pay-right">
            ¥{{orderInfo && orderInfo.price /100 || 0}}
          </div>
        </div>
        <div class="pay-item">
          <div class="pay-left">
            VIP优惠
          </div>
          <div class="pay-right">
            -¥{{Number((orderInfo && orderInfo.price) - (orderInfo && orderInfo.pay_price))/100}}
          </div>
        </div>
        <div class="pay-item">
          <div class="pay-left">
            合计
          </div>
          <div class="pay-right">
            ¥{{orderInfo && orderInfo.pay_price/100 || 0}}
          </div>
        </div>
      </div>
      <div class="pay-type">
        <div class="pay-title">支付方式</div>
        <div class="type-list">
          <div class="type-item" v-for="(item,idx) in typeList" :key="idx" @click="changeType(item)">
            <div class="type-check" v-if="chooseType == item.type">
              <i class="el-icon-check"></i>
            </div>
            <div class="pay-img">
              <img :src="item.payImg">
            </div>
            <div class="pay-name">{{item.name}}</div>
          </div>
        </div>
      </div>
      <div class="pay-code">
        <div class="code-info">待支付金额<div class="money"><span class="unit">¥</span>{{orderInfo && orderInfo.pay_price /
            100 || 0}}
          </div>
        </div>
        <div class="qrcode">
          <!-- <img src="@/assets/images/wechat_3.jpg"> -->
          <canvas ref="qrcodeCanvas"></canvas>
        </div>
        <div class="bottom-desc">手机微信扫一扫直接支付</div>
      </div>
    </div>
  </div>
</template>
<script>
  import {
    statisticsOrderCreate,
    statisticsOrderPay,
    getStatisticsOrderInfo,
  } from "@/api/statisticsOrder";
  import QRCode from "qrcode";
  import { EventBus } from '@/utils/eventBus.js';
  export default {
    props: ["payInfo", "isFromOrder", "orderCurrentInfo"],
    data() {
      return {
        typeList: [
          //     {
          //     name: "支付宝支付",
          //     type: 1,
          //     payImg: require("@/assets/images/zfb.png")
          // },
          {
            name: "微信支付",
            type: 2,
            payImg: require("@/assets/images/wechat.png"),
          },
        ],
        chooseType: 2,
        canClick: true,
        loadingPopup: null,
        payInter: null,
        orderInfo: null
      };
    },
    created() {
      console.log("payInfo", this.payInfo);
      if (this.isFromOrder && this.orderCurrentInfo) {
        this.orderInfo = this.orderCurrentInfo;
        this.statisticsOrderPayReq();
      } else {
        this.statisticsOrderCreateReq();
      }

    },
    beforeDestroy() {
      this.clearPayInter();
    },
    methods: {
      clearPayInter() {
        this.payInter && clearInterval(this.payInter);
      },
      close() {
        this.$emit("closePay");
      },
      changeType(item) {
        this.chooseType = item.type;
      },
      statisticsOrderCreateReq() {
        if (!this.canClick) {
          return;
        }
        this.canClick = false;
        let param = {
          price: this.payInfo.price,
          product_id: this.payInfo.id,
        };
        this.loadingPopup = this.$loading({
          lock: true,
          text: "创建订单中,请稍后",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        statisticsOrderCreate(param)
          .then((res) => {
            console.log("coinOrderCreate", res);
            this.canClick = true;
            if (res && res.error == 0) {
              this.orderInfo = res.data || null;
              this.statisticsOrderPayReq();
            } else {
              this.loadingPopup.close();
              this.$message({
                message: (res && res.msg) || "创建订单失败，请稍后重试",
                type: "err",
              });
            }
          })
          .catch((err) => {
            this.canClick = true;
            this.$message({
              message: (err && err.msg) || "服务器异常，请稍后重试",
              type: "err",
            });
            this.loadingPopup.close();
          });
      },
      statisticsOrderPayReq() {
        let self = this;
        if (self.isFromOrder) {
          self.loadingPopup = this.$loading({
            lock: true,
            text: "创建支付二维码中,请稍后",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
        }

        if (self.orderInfo && self.orderInfo.id) {
          if (!self.canClick) {
            return;
          }
          self.canClick = false;
          let param = {
            channel: "wx_native",
            id: self.orderInfo && self.orderInfo.id,
          };
          statisticsOrderPay(param)
            .then((res) => {
              if (res && res.error == 0 && res.data && res.data.code_url) {
                self.loadingPopup.close();
                // let loading = this.$loading({
                //     lock: true,
                //     text: '创建支付二维码中,请稍后',
                //     spinner: 'el-icon-loading',
                //     background: 'rgba(0, 0, 0, 0.7)'
                // });
                let data = res;

                self.generateQRCode(res.data.code_url);
                self.payInter = setInterval(() => {
                  self.getStatisticsOrderInfoReq(self.orderInfo.id);
                }, 1000);
              } else {
                self.loadingPopup.close();
              }
            })
            .catch((err) => {
              self.loadingPopup.close();
              self.canClick = true;
              self.$message({
                message: (err && err.msg) || "服务器异常，请稍后重试",
                type: "err",
              });
            });
        } else {
          this.canClick = true;
          this.loadingPopup.close();
          this.$message({
            message: "暂无订单信息",
            type: "info",
          });
        }
      },
      generateQRCode(url) {
        QRCode.toCanvas(this.$refs.qrcodeCanvas, url, (error) => {
          if (error) console.error(error);
        });
      },
      getStatisticsOrderInfoReq(id) {
        getStatisticsOrderInfo(id).then((res) => {
          console.log("res", res);
          if (res && res.error == 0 && res.data && res.data.status == 3) {
            this.$message({
              message: "支付完成",
              type: "success"
            });
            EventBus.$emit("orderHasPay")
            this.$bus.$emit("paySuccess");
            this.$bus.$emit("refreshHeaderUser");
            if (this.isFromOrder) {
              this.$bus.$emit("payOrderSuccess")
            }
            
            this.clearPayInter();
            this.$emit("closePay");
          }
        });
      },
    },
  };
</script>
<style lang="less" scoped>
  .pay-warp-popup {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: transparent;
    z-index: 100001;

    .pay-popup-content {
      width: 380px;
      background: #ffffff;
      box-shadow: 0px 4px 8px 0px #e7e8ed;
      border-radius: 20px;
      border: 1px solid #e7e8ed;
      left: 132px;
      bottom: 20px;
      top: 128px;
      position: absolute;
      overflow-y: auto;

      .product-info {
        width: 340px;
        padding: 15px 20px 10px 20px;
        background: #d9e5ff;
        border-radius: 10px;
        margin: 0 auto;
        margin-top: 14px;

        .product-title {
          font-weight: bold;
          font-size: 14px;
          color: #1a232f;
          line-height: 20px;
          text-align: left;
        }

        .product-desc {
          font-weight: 400;
          font-size: 12px;
          color: #626a83;
          line-height: 17px;
          text-align: justify;
          margin-top: 4px;
        }
      }

      .pay-info {
        width: 340px;
        background: #f8f8fa;
        padding: 20px;
        border-radius: 10px;
        margin: 0 auto;
        margin-top: 10px;

        .pay-item {
          display: flex;
          justify-content: space-between;
          padding: 10px 0;

          .pay-left {
            font-weight: 400;
            font-size: 14px;
            color: #626a83;
            line-height: 22px;
          }

          .pay-right {
            font-weight: bold;
            font-size: 14px;
            color: #1a232f;
            line-height: 22px;
            text-align: right;

            .discount {
              color: #ff5959;
            }
          }
        }
      }

      .pay-type {
        width: 340px;
        padding: 16px 20px 18px 20px;
        background: #f8f8fa;
        border-radius: 10px;
        margin: 0 auto;
        margin-top: 10px;

        .pay-title {
          font-weight: bold;
          font-size: 14px;
          color: #1a232f;
          line-height: 22px;
          margin-bottom: 10px;
          text-align: left;
        }

        .type-list {
          display: flex;
          justify-content: space-between;
        }

        .type-item {
          width: 140px;
          height: 36px;
          background: #ffffff;
          box-shadow: 0px 2px 4px 0px #e7e8ed;
          border-radius: 5px;
          border: 1px solid #e7e8ed;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          position: relative;

          .pay-img {
            img {
              width: 24px;
              height: 24px;
            }
          }

          .pay-name {
            font-weight: 400;
            font-size: 14px;
            color: #626a83;
            margin-left: 10px;
          }

          .type-check {
            position: absolute;
            top: 0;
            right: 0;
            width: 16px;
            height: 16px;
            background: #3672fd;
            border-radius: 0px 5px 0px 5px;
            display: flex;
            align-items: center;
            justify-content: center;

            i {
              font-size: 12px;
              color: #fff;
            }
          }
        }
      }

      .pay-code {
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        align-items: center;

        .code-info {
          font-weight: 400;
          font-size: 12px;
          color: #626a83;
          display: flex;
          align-items: center;

          .money {
            font-weight: bold;
            font-size: 20px;
            color: #3672fd;
            line-height: 20px;
            margin-left: 10px;

            .unit {
              font-size: 14px;
            }
          }
        }

        .qrcode {
          margin-top: 10px;

          img {
            width: 120px;
            height: 120px;
          }

          canvas {
            width: 120px;
            height: 120px;
          }
        }

        .bottom-desc {
          font-weight: 400;
          font-size: 12px;
          color: #979db1;
          line-height: 17px;
          margin-top: 10px;
        }
      }
    }
  }
</style>