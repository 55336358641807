<template>
  <div class="product-box">
    <div class="product-center">
      <div class="product-list" style="padding: 30px;">
        <div class="product-item product-item1">

          <div class="product-info">
            <div class="sub-desc" style="padding-right: 30px;">AI+大数据赋能的传统玩具行业，打造精准市场预测、产品研发、销售策略、私域流量决绝方案！为玩具厂家和从业者打造的AI时代专属服务。</div>
          </div>
          <div class="product-img">
            <img src="@/assets/images/common/sjqd.png">
          </div>
        </div>
      </div>
      <div class="product-list" style="background: #EBEEF5;padding: 30px;">

        <div class="product-item product-item1" v-for="(item,idx) in productList" :key="idx" :id="item.elementId"
          @click="goAnalysis(item.type)">

          <div class="product-info">
            <SecondTitle v-if="item.name" :secTitle="item.name">
            </SecondTitle>
            <div class="desc-title" v-if="item.intro">功能简介</div>
            <div class="desc" v-if="item.intro" v-html="item.intro"></div>

            <div class="desc-title">功能亮点</div>
      
            <div class="desc" v-html="item.desc">
            </div>

          </div>
          <div class="product-img" style="margin-left: 20px;">
            <img :src="item.imgSrc">
            <div class="opt" v-if="item.type">
              <div class="btn">立即体验</div>
            </div>
            <div class="opt" style="margin-top: 20px" v-if="item.isCode">
              
              <el-popover class="code-item" placement="top" width="280" trigger="click">
                <div class="">
                    <img src="@/assets/images/qiyewx.png">
                </div>
                <div slot="reference" class="btn cursor">立即定制</div>
              </el-popover>

            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
  import SecondTitle from "@/components/secondTitle/index.vue";
  import { getToken } from "@/utils/auth";
  import { getUserStatisticsCats } from "@/api/user";
  export default {
    components: {
      SecondTitle,
    },
    data() {
      return {
        productList: [

          {
            name: "市场预测",
            intro: "市场预测功能：包括品类市场预测、IP表现预测、蓝海市场预测。",
            desc: "通过回归模型、趋势分析等多种模型相结合，精准预测玩具玩具市场各品类未来市场表现。<br>结合季节性要素精准预测市场热点。<br>结合用户需求和IP形象特点智能预测文化热点对玩具销量的影响。",
            dsDesc: "侧重于帮助电商平台优化产品的选品组合。",
            cjDesc: "侧重于帮助玩具厂商制定产品开发计划和市场定位，挖掘潜力IP。",
            imgSrc: require("@/assets/images/common/scyc.png"),
            elementId: "report",
            type: "1-1",
          },
          // {
          //   name: "策略分析",
          //   desc: "通过我们的创新解决方案，您将获得无与伦比的市场洞察力，从而精准把握市场机遇和消费者需求，助力您制定高效的产品策略，抢占市场先机，获得成功",
          //   imgSrc: require("@/assets/images/home/icon_2.png"),
          //   elementId: "strategy",
          //   type: "1-2",
          // },
          {
            name: "IP分析",
            intro: "IP分析功能：详细动漫、影视、游戏IP的人群特征以及其玩具衍生品的市场表现。",
            desc: "全面信息洞察：提供IP的综合信息，包括基本信息、标签、用户评分等，快速了解IP的基本指数和市场定位。<br>精准市场匹配：通过应用建议和同类IP分析，评估IP与各玩具品类的适配度和市场表现。<br>消费者与渠道分析：分析IP的人群年龄分布和媒体渠道热度，深入了解市场动态和消费者需求，优化IP推广策略，实现精准市场定位。",
            imgSrc: require("@/assets/images/common/ipfx.png"),
            dsDesc: "侧重于帮助电商平台优化IP产品选品组合和定价策略。",
            cjDesc: "侧重于帮助玩具厂商制定IP应用策略和市场定位。",
            type: "1-3",
          },
          {
            name: "新品预演",
            intro: "依托强大的市场预测大数据和专项训练的AI模型，为玩具行业打造的专属AI助手。为厂商、渠道商和电商业主提供智能化市场预演工具，生产或销售符合目市场需要的产品。",
            desc: "市场洞察与竞争力提升：通过市场现状分析和IP竞争力评估，帮助了解市场结构和消费者需求，优化产品组合和定价策略，提升市场竞争力和盈利能力。<br>精准预测与策略优化：提供爆款概率预测和详细的爆款策略建议，从创新设计到市场推广，为提供全方位的指导，助力产品在市场中脱颖而出。<br>智能交互与个性化支持：通过自由问答功能，可以就玩具的任何问题与AI互动，获得个性化的解答和建议。",
            imgSrc: require("@/assets/images/common/xpyy.png"),
            dsDesc: "侧重于帮助电商平台优化产品市场推广和定价策略。",
            cjDesc: "侧重于帮助制造商制定新产品开发指导、IP应用策略和市场定位。",
            type: "1-4",
          },
          {
            name: "私域流量解决方案",
            intro: "",
            desc: "以AI云服务与私域商城结合。<br>无需附加电子元器件,可为每件玩具专属定制云服务。<br>变公域销量为私域流量，打造自有销售渠道。",
            imgSrc: require("@/assets/images/common/syll.png"),
            dsDesc: "侧重于帮助电商平台优化产品市场推广和定价策略。",
            cjDesc: "侧重于帮助制造商制定新产品开发指导、IP应用策略和市场定位。",
            type: "",
            isCode: true
          },
          {
            name: "产品云服务定制开发",
            intro: "",
            desc: "快速打造玩具企业专属面向的目标用户的AI工具。<br>为每个玩具产品开发部署专属定制AI云服务。<br>让AI为玩具赋能，提供更智能，更个性化的体验。",
            imgSrc: require("@/assets/images/common/yfw.png"),
            dsDesc: "侧重于帮助电商平台优化产品市场推广和定价策略。",
            cjDesc: "侧重于帮助制造商制定新产品开发指导、IP应用策略和市场定位。",
            type: "",
            isCode: true
          },
        ],
        isLogin: getToken() ? true : false,
        params: {
          offset: 0,
          limit: 20,
        },
      };
    },
    methods: {
      async goAnalysis(type) {
        if (!type) {
          return;
        }
        this.isLogin = getToken() ? true : false;
        if (!this.isLogin) {
          this.$bus.$emit("openLogin");
          return;
        }
        let catInfo = await getUserStatisticsCats(this.params);
        console.log("catInfo", catInfo)
        if (type == "1-4") {
          this.$router.push({
            path: "/hotAi",
          });
        } else {
          if (catInfo && catInfo.data && catInfo.data.days > 0) {
            switch (type) {
              case "1-1":
                this.$router.push({
                  path: "/analysis",
                });
                break;
              case "1-2":
                this.$router.push({
                  path: "/idea",
                });
                break;
              case "1-3":
                this.$router.push({
                  path: "/ipInfo",
                });
                break;
              case "1-4":
                this.$router.push({
                  path: "/hotAi",
                });
                break;
            }
          } else {
            this.$message({
              message: "请先购买本产品",
              type: "error",
            });
            this.goMy();
          }
        }
      },
      goMy() {
        this.$router.push({
          path: "/user",
        });
      },
    },
  };
</script>
<style lang="less" scoped>
  .product-box {
    width: 100%;

    // overflow-y: auto;
    // overflow-x: hidden;
    .product-center {
      // width: 1200px;
      display: flex;
      margin: 0 auto;
      flex-direction: column;

      .top-header {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 50px 0;

        .btn {
          width: 198px;
          height: 56px;
          background: #3672fd;
          border-radius: 10px;
          font-weight: bold;
          font-size: 20px;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .product-list {
        display: flex;
        flex-direction: column;
        /* margin-top: 50px; */
        width: 100%;

        .product-item {
          display: flex;
          margin-bottom: 30px;
          cursor: pointer;
          width: 100%;

          &.product-item1 {
            background: #ffffff;
            padding: 30px 30px 30px 0;
            border-radius: 20px;
          }

          .product-img {
            display: flex;
            flex-direction: column;
            img {
              width: 448px;
              height: 256px;
              border-radius: 10px;
            }
          }

          .product-info {
            display: flex;
            flex-direction: column;
            padding-left: 30px;
            flex: 1;

            .sub-desc {
              font-size: 28px;
              font-weight: bold;
              color: #1A232F;
              line-height: 36px;

              margin-top: 20px;
            }

            .desc {
              font-weight: 400;
              font-size: 18px;
              color: #626a83;
              line-height: 24px;
              text-align: justify;
              font-style: normal;
              margin-top: 8px;

              flex: 1;
            }

            .second-title {
              font-size: 24px;
              font-weight: bold;
              color: #1A232F;
              line-height: 28px;

              margin-top: 20px;
            }

            .desc-title {
              font-size: 18px;
              font-weight: bold;
              color: #1A232F;
              line-height: 24px;

              margin-top: 20px;
            }

            .second-desc {
              font-size: 18px;
              font-weight: bold;
              color: #1A232F;
              line-height: 24px;

            }


          }

          .opt {
            display: flex;
            flex: 1;
            justify-content: center;
            align-items: center;

            .btn {
              width: 150px;
              height: 40px;
              background: #3672fd;
              border-radius: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-weight: bold;
              font-size: 17px;
              color: #ffffff;
              line-height: 24px;
            }
          }
        }
      }
    }
  }
</style>