<template>
  <div class="market-center-box">
    <!-- <div class="market-item">
      <div class="market-info">
          <div class="name">玩具行业BI助手</div>
        
          <div class="delay">剩余时间：{{catInfo && catInfo.days}}天</div>
        </div>
        
    </div> -->
    <div class="market-list">
      <div class="market-item"
        v-for="(item,idx) in catsList" :key="idx">
        <div class="market-info">
          <div class="name">{{item.cat && item.cat.name}}</div>
          <div class="desc" v-html="item.cat && item.cat.desc"></div>
          <div class="delay">剩余时间：{{item.days}}天</div>
        </div>
        <!-- <div class="market-opt">
          <div class="market-btn in-btn" :class="[catInfo && catInfo.days <= 0 && 'disable-btn']"
            @click="goAnalysis(item,idx + 1)">进入</div>
        </div> -->
      </div>
    </div>
    <el-empty v-if="!catsList || (catsList && catsList.length == 0)" :image-size="200"></el-empty>
    <MarketPay class="pay-popup" v-if="showMarketPay"
      @closeMarketPay="closeMarketPay" :marketList="marketList"></MarketPay>
  </div>
</template>
<script>
import MarketPay from "./marketPay.vue";
import { getStatisticsOrderProducts } from "@/api/statisticsOrder";
import { getUserStatisticsCats } from "@/api/user";
import { EventBus } from '@/utils/eventBus.js';
export default {
  components: {
    MarketPay,
  },
  data() {
    return {
      marketList: [
      ],
      catsList: [],
      showMarketPay: false,
      catInfo: {

      },
      params: {
          offset: 0,
          limit: 20
      },
    };
  },
  created() {
    this.getUserStatisticsCatsReq();
    // this.getStatisticsOrderProductsReq();

    this.$bus.$off("initData");
    this.$bus.$on("initData",()=> {
      this.getUserStatisticsCatsReq();
      // this.getStatisticsOrderProductsReq();
    })

    this.$bus.$off("paySuccess");
    this.$bus.$on("paySuccess",()=> {
      
      this.getUserStatisticsCatsReq();
      // this.getStatisticsOrderProductsReq();
      this.showMarketPay = false;
    })

    
    
    EventBus.$off("orderHasPay");
    EventBus.$on("orderHasPay",()=> {
      
      this.getUserStatisticsCatsReq();
      // this.getStatisticsOrderProductsReq();
      this.showMarketPay = false;
    })
  },
  methods: {
    goAnalysis(item,type) {
      if(item.days <= 0) {
        this.$message({
          message: "请先购买本产品",
          type: "error"
        })
        return;
      }
      switch (type) {
        case 1:
          this.$router.push({
            path: "/analysis?currentId=" + (item.cat && item.cat.id),
          });
          break;
        case 2:
          this.$router.push({
            path: "/idea?currentId=" + (item.cat && item.cat.id),
          });
          break;
        case 3:
          this.$router.push({
            path: "/ipInfo?currentId=" + (item.cat && item.cat.id),
          });
          break;
      }
    },
    closeMarketPay() {
      this.showMarketPay = false;
    },
    getUserStatisticsCatsReq() {
      getUserStatisticsCats(this.params).then((res) => {
        this.catsList = res && res.data && res.data.list || [];
        this.catInfo = res && res.data || null;
      }).catch(() => {
        this.catsList = [];
        this.catInfo = null;
      })
    },
    getStatisticsOrderProductsReq() {
      getStatisticsOrderProducts().then((res) => {
        console.log("res",res)
        this.marketList = res && res.data && res.data.list || [];
      }).catch(()=> {

      })
    }
  },
};
</script>
<style lang="less" scoped>
.market-center-box {
  padding: 30px;
  position: relative;
  height: 100%;
  .pay-popup {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: #f8f8fa;
    border-radius: 20px;
    z-index: 10;
  }
 
  .market-item {
      display: flex;
      padding: 20px;
      background: #ffffff;
      border-radius: 10px;
      margin-bottom: 10px;
      
      .market-info {
        flex: 1;
        .name {
          font-weight: bold;
          font-size: 17px;
          color: #1a232f;
          line-height: 24px;
          text-align: left;
        }
        .desc {
          font-weight: 400;
          font-size: 14px;
          color: #626a83;
          line-height: 20px;
          text-align: left;
          margin-top: 3px;
        }
        .delay {
          font-weight: 400;
          font-size: 14px;
          color: #626a83;
          line-height: 20px;
          text-align: left;
          margin-top: 6px;
        }
      }
      .market-opt {
        display: flex;
        align-items: flex-end;
        .market-btn {
          width: 64px;
          height: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 400;
          font-size: 12px;
          margin-right: 10px;
          cursor: pointer;
          &:last-child {
            margin-right: 0;
          }
        }
        .pay-btn {
          background: #3672fd;
          box-shadow: 0px 2px 4px 0px #d9e5ff;
          border-radius: 18px;
          color: #ffffff;
        }
        .in-btn {
          background: #d9e5ff;
          box-shadow: 0px 2px 4px 0px #d9e5ff;
          border-radius: 18px;
          border: 1px solid #3672fd;
          color: #3672fd;
        }
        .disable-btn {
          background: #e7e8ed;
          box-shadow: 0px 2px 4px 0px #e7e8ed;
          border-radius: 18px;
          border: 1px solid #cccfda;
          color: #979DB1;
        }
      }
    }
  .market-list {
    display: flex;
    flex-direction: column;
    margin-left: 30px;
  }
}
</style>
