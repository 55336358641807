<template>
  <div class="header-top-box">
    <div class="header-content">
      <div class="header-left">
        
        <div class="avatar-box" v-if="isLogin && userInfo && userInfo.avatar" @click="goMy">
          <img class="cursor" :src="userInfo && userInfo.avatar">
        </div>
        <div class="avatar-box" v-else  @click="goLogin">
          <img class="cursor" src="@/assets/images/default_avatar.png">
        </div>
        <div class="user-info" v-if="isLogin">
          <div class="info-left">
            <div class="name cursor" @click="goMy">
              {{userInfo && userInfo.nickname || ""}}
              <!-- <img
                src="https://files.sssrise.com/files/uploads/static/cyhz.png">
              <img
                src="https://files.sssrise.com/files/uploads/static/zshz.png">
              <img
                src="https://files.sssrise.com/files/uploads/static/xzhz.png"> -->
            </div>
            <!-- <div class="vip-info">
              <div class="vip-level cursor" @click="goVip">VIP
                {{userInfo && userInfo.vip_level || 0}}
              </div>
            </div> -->
          </div>
          <!-- <div class="info-right">
            <div class="point-item cursor" @click="goMy(1)">
              <div class="desc">我的收入</div>
              <div class="count">
                {{(userInfo && userInfo.income ? parseFloat(userInfo.income / 100).toFixed(0) : 0 )}}
              </div>
            </div>
            <div class="point-item cursor" @click="goMy(2)">
              <div class="desc">我的积分</div>
              <div class="count">
                {{userInfo && userInfo.coin || 0}}</div>
            </div>
          </div> -->
        </div>
        <div class="user-info" v-else>
          <div class="login-btn cursor" @click="goLogin">
            登录/注册</div>

        </div>
      </div>
      <div class="header-center">
        <div class="tab-list">
          <div class="tab-item cursor"
            :class="[menuIdx == item.index ? 'active' : '']"
            v-for="(item,idx) in tabList" :key="idx"
            @click="goPath(item)">{{item.name}}
            <div v-if="item.subName" class="sub-name">{{item.subName || ""}}</div>
          </div>
        </div>
        <!-- <el-menu :default-active="menuIdx" class="el-menu-demo menu-list" mode="horizontal" @select="handleSelect">
          <MenuCom :menuList="menuList" :menuIdx="menuIdx"></MenuCom>
        </el-menu> -->
      </div>
      <div class="header-right cursor" @click="goIndex()">
        <img class="cursor" src="@/assets/images/logo.png" >
        <div class="mt-10">玩具行业决策分析系统</div>
      </div>
    </div>

  </div>
</template>
<script>
import { getToken, removeToken } from "@/utils/auth";
import util from "@/utils/util";
// import MenuCom from "./menu.vue";
import { getUserStatisticsCats } from "@/api/user";
export default {
  components: {
    // MenuCom
  },
  data() {
    return {
      menuList: [
      {
        name: "玩具行业产品服务",
        pathName: "Service",
        index: "1",
        children: [{
          name: "玩具市场分析",
          pathName: "Analysis",
          subName: "",
          index: "1-1",
        },
        {
          name: "产品策略分析",
          pathName: "Idea",
          subName: "",
          index: "1-2",
        },
        {
          name: "IP分析",
          pathName: "IpInfo",
          subName: "",
          index: "1-3",
        },{
          name: "爆款打造专家",
          pathName: "HotAi",
          subName: "",
          index: "1-4",
        }]
      }],
      menuIdx: "",
      tabList: [
        {
          name: "市场预测",
          type: 1,
          pathName: "Analysis",
          index: "1-1",
        },
        // {
        //   name: "策略分析",
        //   type: 2,
        //   pathName: "Idea",
        //   index: "1-2",
        // },
        {
          name: "IP分析",
          type: 3,
          pathName: "IpInfo",
          index: "1-3",
        },{
          name: "新品预演",
          subName: "AI",
          pathName: "HotAi",
          type: 4,
          index: "1-4",
        },{
          name: "产品定价",
          pathName: "ProductPrice",
          type: 5,
          index: "1-5",
        }
      ],
      activeType: 0,
      isLogin: getToken() ? true : false,
      userInfo: {
        id: "",
        nickname: "",
        mobile: "",
        avatar: "",
        gender: "",
        desc: "",
        real_name: "",
        character: "",
        vip_level: 0,
        vip_expires_in: "",
        coin: 0,
        income: 0,
        balance: 0,
        freeze: 0,
        token: "",
      },
      params: {
          offset: 0,
          limit: 20
      },
      catInfo: null,
      menuItem: null
    };
  },
  created() {
    this.initType();

    util.onLogin().then((res) => {
      if (!res) {
        let routeName = this.$route.name;
        
        // this.$bus.$emit("openLogin");
      } else {
        this.getUserInfo();
        this.getUserStatisticsCatsReq();
      }

    });

    this.$bus.$off("initUser");
    this.$bus.$on("initUser",()=> {
      this.isLogin = getToken() ? true : false;
      if (this.isLogin) {
        this.getUserInfo();
        this.getUserStatisticsCatsReq();
      }
    })

    this.$bus.$off("refreshHeaderUser");
    this.$bus.$on("refreshHeaderUser",()=> {
      this.isLogin = getToken() ? true : false;
      if (this.isLogin) {
        this.getUserInfo();
        this.getUserStatisticsCatsReq();
      }
    })

    this.$bus.$off("loginout");
    this.$bus.$on("loginout",()=> {
      this.isLogin = false;
      this.userInfo = {
        id: "",
        nickname: "",
        mobile: "",
        avatar: "",
        gender: "",
        desc: "",
        real_name: "",
        character: "",
        vip_level: 0,
        vip_expires_in: "",
        coin: 0,
        income: 0,
        balance: 0,
        freeze: 0,
        token: "",
      }
    })
  },
  watch: {
    $route() {
      this.initType();
    },
  },
  methods: {
    imgError(event) {
      // event.target.src = '@/assets/images/default_avatar.png';
    },
    getUserStatisticsCatsReq() {
      getUserStatisticsCats(this.params).then((res) => {
        this.catInfo = res && res.data || null;
      }).catch(() => {
        this.catInfo = null;
      })
    },
    goAnalysis(type) {
      switch (type) {
        case "1-1":
          this.$router.push({
            path: "/analysis",
          });
          break;
        case "1-2":
          this.$router.push({
            path: "/idea",
          });
          break;
        case "1-3":
          this.$router.push({
            path: "/ipInfo",
          });
          break;
        case "1-4":
          this.$router.push({
            path: "/hotAi",
          });
          break;
        case "1-5":
          this.$router.push({
            path: "/productPrice",
          });
          break;
      }
    },
    handleSelect(val,indexPath) {
      console.log("val",val,"indexPath",indexPath)
      if(!this.isLogin) {
        this.menuIdx = "";
        this.goLogin();
        return;
      }

      let hasPay = false;
      if(this.catInfo && this.catInfo.list && this.catInfo.list > 0) {
        for(let i = 0,j = this.catInfo.list;i<j;i++) {
          let tempItem = this.catInfo.list[i];
          if(tempItem.days > 0) {
            hasPay = true;
            break;
          }
        }
      }
      console.log("hasPay",hasPay)
      if(hasPay) {
        this.goAnalysis(val)
      } else {
        this.$message({
          message: "请先购买本产品",
          type: "error"
        })
        this.goMy();
      }
    },
    initType() {
      // let routeName = this.$route.name;
      // let activeType = this.tabList.findIndex((res) => {
      //   return (
      //     res.pathName == routeName ||
      //     (res.subPathName && res.subPathName.indexOf(routeName) >= 0)
      //   );
      // });
      // if (activeType >= 0) {
      //   this.activeType = activeType + 1;
      // }
      let routeName = this.$route.name;
      this.menuItem = this.tabList.find((res) => {
        return res.pathName.toLowerCase() == routeName.toLowerCase();
      });
      if(this.menuItem) {
        this.menuIdx = this.menuItem.index;
      } else {
        this.menuIdx = "-1";
      }
      console.log("menuIdx",this.menuIdx)
    },
    goLogin() {
      this.$emit("goLogin");
    },
    goMy() {
      this.$router.push({
        path: "/user",
      });
    },
    goVip() {
      this.$emit("goVip",this.userInfo)
    },
    goIndex() {
      this.$router.push({
        path: "/"
      })
    },
    goPath(item) {
      // if(!this.isLogin) {
      //   this.menuIdx = "";
      //   this.goLogin();
      //   return;
      // }
      console.log("123123")
      if(item.index == "1-5") {
        this.goAnalysis(item.index)
      } else {
        let hasPay = false;
        console.log("this.catInfo",this.catInfo,this.catInfo.list.length)
        if(this.catInfo && this.catInfo.list && this.catInfo.list.length > 0) {
          
          let isExist = this.catInfo.list.filter((res) => {
            return res.days > 0;
          })
          console.log("isExist",isExist)
          if(isExist && isExist.length > 0) {
            hasPay = true;
          } else {
            hasPay = false;
          }
        }
        console.log("hasPay",hasPay)
        if(hasPay) {
          this.goAnalysis(item.index)
        } else {
          this.$message({
            message: "请先购买本产品",
            type: "error"
          })
          this.goMy();
        }
      }
      
      this.activeType = item.type;
    },
    getUserInfo() {
      this.$store
        .dispatch("GetInfo", "")
        .then((res) => {
          this.userInfo = res;
        })
        .catch((err) => {
          this.$message.error((err && err.msg) || "服务器出错，请稍后重试");
        });
    },
    loginout() {
      removeToken();
      this.isLogin = getToken() ? true : false;
    },
    closeLogin() {
      this.showLogin = false;
      this.isLogin = getToken() ? true : false;
      if (this.isLogin) {
        this.getUserInfo();
        this.$bus.$emit("initData", true);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.header-top-box {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 999;
  background: #fff;
  height: 128px;
  min-width: 1440px;
  .header-content {
    display: flex;
    align-items: center;
    height: 100%;
    .header-left {
      width: 450px;
      display: flex;
      align-items: center;
    }
    .avatar-box {
      margin-right: 30px;
      margin-left: 30px;
      img {
        width: 70px;
        height: 70px;
        border-radius: 100%;
      }
    }
    .user-info {
      display: flex;
      flex: 1;
      .login-btn {
        width: 100px;
        height: 36px;
        background: #3672fd;
        box-shadow: 0px 2px 4px 0px #d9e5ff;
        border-radius: 18px;
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .info-left {
        flex: 1;
        .name {
          font-weight: bolder;
          font-size: 17px;
          color: #000000;
          line-height: 24px;
          text-align: left;
          display: flex;
          align-items: center;
          img {
            width: 20px;
            height: 22px;

            &:first-child {
              margin-left: 6px;
            }
          }
        }
        .vip-info {
          display: flex;
          .vip-level {
            min-width: 48px;
            padding: 0 6px;
            height: 20px;
            background: linear-gradient(135deg, #626a83 0%, #1a232f 100%);
            border-radius: 0px 10px 10px 10px;
            font-weight: normal;
            font-size: 14px;
            color: #eccd8e;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
      .info-right {
        display: flex;
        flex-direction: column;
        .point-item {
          display: flex;
          padding-right: 30px;
          align-items: center;
          .desc {
            font-weight: 400;
            font-size: 12px;
            color: #979db1;
            line-height: 17px;
          }
          .count {
            font-weight: bold;
            font-size: 18px;
            color: #3672fd;
            line-height: 21px;
            margin-left: 6px;
          }
        }
      }
    }
    .header-center {
      flex: 1;
      .tab-list {
        display: flex;
        .tab-item {
          padding: 0 30px;
          height: 70px;
          font-weight: 500;
          font-size: 24px;
          color: #626a83;
          line-height: 70px;
          position: relative;
          &.active {
            color: #3672fd;
            font-weight: bold;
            .sub-name {
              /* background: #3672fd; */
            }
          }
          .sub-name {
            font-size: 18px;
            position: absolute;
            top: 4px;
            right: -6px;
            /* border: 1px solid #626a83; */
            display: flex;
            height: 26px;
            line-height: 26px;
            align-items: center;
            padding: 0 10px;
            border-radius: 10px;
            background: #f56c6c;
            color: #fff;
          }
        }
      }
      .el-menu.el-menu--horizontal {
            border-bottom: none;
        }
    }
    .header-right {
      width: 300px;
      display: flex;
      align-items: center;
      flex-direction: column;
      img {
        width: 230px;
        height: 50px;
      }
    }
  }
}
</style>