<template>
  <div class="market-order-box">
    <div class="market-pay-top" v-if="showHeader">
      <i class="el-icon-back icon-back"
        @click="closeMarketOrder"></i>
      <div class="order-tab-list">
        <div class="order-tab-item cursor"
          :class="[orderTypeIdx == idx ? 'active' : '']"
          v-for="(item,idx) in orderTypeList" :key="idx"
          @click="selectMyTab(item,idx)">
          {{item.name}}</div>
      </div>
    </div>
    <div class="my-order-list" @scroll="handleScroll"
      v-if="orderList && orderList.length > 0">
      <div class="my-order-item flex-column"
        v-for="(item,idx) in orderList" :key="idx">
        <div class="flex justify-between order-top">
          <div class="order-left">
            <div class="name">
              {{item.product && item.product.cat && item.product.cat.name}}</div>
            <div class="desc">
              {{item.product && item.product.desc}}
            </div>
            <div class="desc">{{item.created_at}}</div>
          </div>
          <div class="order-right">
            <div class="status"
              :style="{color: calStatusColor(item.status)}">
              {{item.status_cn}}</div>
            <div class="count"><span class="unit">¥
              </span>{{item.pay_price / 100}}</div>
          </div>
        </div>
        <div class="flex justify-end mt-20 order-bottom"
          v-if="item.status == 0">
          <div class="cancel-btn mr-20 cursor"
            @click="cacelOrder(item)">取消订单</div>
          <div class="submit-primary-btn cursor"
            @click="goPay(item)">
            <el-statistic
              v-if="defaultConfig && defaultConfig.coin_order && defaultConfig.coin_order.cancel_time"
              :value="Date.now() +  defaultConfig.coin_order.cancel_time * 1000"
              time-indices>
            </el-statistic>

            <div class="ml-04" style="width: 50px;">去支付
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-empty
      v-if="!orderList || (orderList && orderList.length == 0)"
      :image-size="200"></el-empty>
  </div>
</template>
<script>
import { getDefaultConfig } from "@/api/user";
import {
  getStatisticsOrderList,
  statisticsOrderCancel,
  getStatisticsOrderInfo,
} from "@/api/statisticsOrder";
import { MessageBox } from "element-ui";
export default {
  props: {
    showHeader: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      orderTypeList: [
        {
          id: 0,
          name: "全部订单",
        },
        {
          id: 0,
          name: "待支付",
        },
      ],
      orderTypeIdx: 0,
      orderList: [],
      defaultConfig: null,
      orderParams: {
        status: 99,
        pageSize: 0,
        offset: 0,
        limit: 20,
      },
      isLoading: false,
      currentItem: null,
    };
  },
  created() {
    this.getDefaultConfigReq();
    this.getStatisticsOrderListReq();

    this.$bus.$off("payOrderSuccess");
    this.$bus.$on("payOrderSuccess", () => {
      this.getStatisticsOrderInfoReq(this.currentItem.id);
    });
  },
  methods: {
    cacelOrder(item) {
      let self = this;
      self.currentItem = item;
      MessageBox.confirm("确定取消该订单", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          statisticsOrderCancel({
            id: self.currentItem.id,
          })
            .then((res) => {
              console.log("coinOrderCancel", res);
              if (res && res.error == 0) {
                self.getStatisticsOrderInfoReq(self.currentItem.id);
              } else {
                self.$message({
                  message: (res && res.msg) || "服务器异常，请稍后重试",
                  type: "error",
                });
              }
            })
            .catch((err) => {
              self.$message({
                message: (err && err.msg) || "服务器异常，请稍后重试",
                type: "error",
              });
              self.getStatisticsOrderListReq(self.currentItem.id);
            });
        })
        .catch(() => {});
    },
    getStatisticsOrderInfoReq(id) {
      getStatisticsOrderInfo(id).then((res) => {
        console.log("res", res);
        if (res && res.error == 0 && res.data) {
          const isExist = this.orderList.find(
            (item) => item.id === res.data.id
          );
          console.log("isExist", isExist, "orderList", this.orderList);
          if (isExist) {
            let idx = this.orderList.findIndex(
              (item) => item.id === res.data.id
            );
            console.log("idx", idx);
            this.$set(this.orderList, idx, res.data);
          }
        }
      });
    },
    selectMyTab(item, idx) {
      this.orderTypeIdx = idx;
      this.orderList = [];
      this.orderParams.pageSize = 0;
      this.orderParams.offset = 0;
      if (this.orderTypeIdx == 0) {
        this.orderParams.status = 99;
      } else {
        this.orderParams.status = 0;
      }
      this.getStatisticsOrderListReq();
    },
    closeMarketOrder() {
      this.$emit("closeMarketOrder");
    },
    handleScroll() {
      console.log("this.isLoading",this.isLoading,"canLoadMore",this.canLoadMore())
      if (!this.isLoading && this.canLoadMore()) {
        this.isLoading = true;

        this.orderParams.pageSize += 1;
        this.orderParams.offset =
          this.orderParams.pageSize * this.orderParams.limit;
        this.getStatisticsOrderListReq();
      }
    },
    canLoadMore() {
      const { scrollTop, scrollHeight, clientHeight } = event.target;
      // console.log("scrollTop",scrollTop,"scrollHeight",scrollHeight,"clientHeight",clientHeight)
      const isBottom = scrollTop + clientHeight >= scrollHeight - 50;
      return isBottom && this.statusLoadmore == "loadmore";
    },
    getStatisticsOrderListReq() {
      getStatisticsOrderList({
        status: this.orderParams.status,
        offset: this.orderParams.offset,
        limit: this.orderParams.limit,
      })
        .then((res) => {
          console.log("res", res);
          if (res && res.data && res.data.list) {
            res.data.list.forEach((billItem) => {
              const isExist = this.orderList.find(
                (item) => item.id === billItem.id
              );
              const tempItem = {
                id: billItem.id,
                created_at: billItem.created_at,
                pay_price: billItem.pay_price,
                price: billItem.price,
                no: billItem.no,
                status: billItem.status,
                status_cn: billItem.status_cn,
                product: billItem.product,
              };
              if (isExist) {
                return;
              } else {
                this.orderList.push(tempItem);
              }
            });
          }

          if (res && res.data && res.data.has_more) {
            this.statusLoadmore = "loadmore";
          } else {
            this.statusLoadmore = "nomore";
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          this.$message({
            message: (err && err.msg) || "服务器异常，请稍后重试",
            type: "error",
          });
        });
    },
    getDefaultConfigReq() {
      getDefaultConfig().then((res) => {
        this.defaultConfig = res.data || null;
      });
    },
    clearOrder() {
      this.orderParams.pageSize = 0;
      this.orderParams.offset = 0;
      this.orderList = [];
    },
    calStatusColor(status) {
      switch (status) {
        case 3:
          return "#3672FD";
          break;
        case 0:
          return "#FF5959";
          break;
        default:
          return "#1A232F";
          break;
      }
    },
    goPay(item) {
      this.currentItem = item;
      this.$emit("goPopupPay", item);
    },
  },
};
</script>
<style lang="less" scoped>
.market-order-box {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .market-pay-top {
    height: 54px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    .icon-back {
      color: #626a83;
      font-size: 20px;
      cursor: pointer;
    }
    .go-order {
      font-weight: 400;
      font-size: 14px;
      color: #3672fd;
      text-align: center;
      font-style: normal;
      cursor: pointer;
    }
  }
  .order-tab-list {
    width: 160px;
    height: 28px;
    background: #e7e8ed;
    border-radius: 16px;
    display: flex;
    margin-top: 20px;
    margin-right: 30px;

    .order-tab-item {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      font-size: 12px;
      color: #1a232f;

      &.active {
        margin-top: 2px;
        height: 24px;
        background: #d9e5ff;
        box-shadow: 0px 2px 4px 0px #d9e5ff;
        border-radius: 16px;
        border: 1px solid #3672fd;
        color: #3672fd;
      }
    }
  }
  .my-order-list {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
    padding: 0 30px;
    margin-top: 20px;

    .my-order-item {
      display: flex;
      padding: 17px 20px 15px 20px;
      width: 100%;
      background: #ffffff;
      border-radius: 10px;
      margin-bottom: 10px;

      .order-top {
        width: 100%;
      }

      .order-bottom {
        ::v-deep .el-statistic {
          .number {
            color: #fff !important;
            font-size: 12px !important;
          }
        }
      }

      .order-left {
        text-align: left;
      }

      .order-right {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .name {
        font-weight: bold;
        font-size: 14px;
        color: #1a232f;
        line-height: 20px;
      }

      .desc {
        font-weight: 400;
        font-size: 12px;
        color: #626a83;
        line-height: 17px;
        margin-top: 5px;
      }

      .status {
        font-weight: 400;
        font-size: 12px;
        color: #3672fd;
        line-height: 17px;
      }

      .count {
        font-weight: bold;
        font-size: 14px;
        color: #1a232f;
        line-height: 20px;

        .unit {
          font-size: 12px;
        }
      }
    }
  }
}
</style>